import { useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useLazyQuery } from '@apollo/client'
import Router from 'next/router'
import { statsigLog } from '@/lib/statsigAndGa'
import GoogleLoginIcon from '../icons/googleLoginIcon'
import { THIRD_LOGIN } from '../../graphqls/queries'

let autoLoginWindow = null

const GoogleLogins = props => {
  const {
    loginSuccess = () => {},
    loginError = () => {},
    handleLoading = () => {},
    children
  } = props
  const [login] = useLazyQuery(THIRD_LOGIN)
  // const getLogin = useGoogleLogin({
  //   flow: 'implicit',
  //   prompt: '', // auto login without choose
  //   onSuccess: tokenResponse => {
  //     const { access_token: googleToken = '', expires_in: expiresIn } = tokenResponse || {}
  //     if (!expiresIn) {
  //       loginError('The google account has expired')
  //       return
  //     }
  //     loginReq(googleToken)
  //   },
  //   onError: res => {
  //     loginError(res)
  //   }
  // })

  const loginReq = (googleToken = '') =>
    login({
      fetchPolicy: 'network-only',
      variables: {
        loginInput: { googleToken }
      },
      onCompleted: res => {
        const { thirdLogin } = res || {}
        const { userInfo = '' } = thirdLogin || {}
        const { token = '' } = userInfo || {}
        document.cookie = `loginUserToken=${token};path=/`
        statsigLog('login_in_ways_click', { login_way: 'google' })
        loginSuccess(res)
        handleLoading(false)
        // userInfoCatch({
        //   email: userEmail,
        //   name: userFirstName || 'anonymous',
        //   userId,
        //   firstName: userFirstName,
        //   lastName: lastUserName
        // })
        Router.reload()
      }
    })

  useEffect(() => {
    // receive token from auth login page by google redirect
    const receiveMessage = event => {
      const { source, data = {} } = event
      if (source !== autoLoginWindow) {
        handleLoading(false)
        return
      }
      const { accessToken = '' } = data
      if (!accessToken) {
        handleLoading(false)
        loginError('Login failed, please try later!')
        return
      }
      loginReq(accessToken)
    }
    window.removeEventListener('message', receiveMessage)
    window.addEventListener('message', receiveMessage)
    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [])

  const openLoginWindow = () => {
    const clientId = process.env.GOOGLE_LOGIN_CLIENT_ID
    const { origin } = window
    const redirectUrl = `${origin}/auth/login`
    const params = [
      'gsiwebsdk=3',
      `client_id=${clientId}`,
      'scope=openid profile email',
      `redirect_uri=${redirectUrl}`,
      'response_type=token&include_granted_scopes=true&enable_serial_consent=true'
    ]
    const { width, height } = window.screen
    const autoLoginWindowWidth = Math.min(760, width)
    const autoLoginWindowHeight = Math.min(480, height)
    const fetures = [
      `width=${autoLoginWindowWidth}`,
      `height=${autoLoginWindowHeight}`,
      `outerWidth=${autoLoginWindowWidth}`,
      `outerHeight=${autoLoginWindowHeight}`,
      'menubar=no',
      'location=yes',
      'resizable=yes',
      'scrollbars=no',
      'status=yes',
      'centerscreen=yes'
    ]
    if (autoLoginWindow && autoLoginWindow.document) {
      // close loginWindow first if exist
      autoLoginWindow.clearInterval?.()
      autoLoginWindow.clearTimeout?.()
      autoLoginWindow.close()
    }

    autoLoginWindow = window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?${params.join('&')}`,
      'oauth',
      fetures.join(',')
    )

    if (!autoLoginWindow) {
      loginError('Error, can not open login window')
      return
    }

    autoLoginWindow.focus()
  }

  const onClickLogin = () => {
    statsigLog('login_in_ways_click', { login_way: 'google' })
    handleLoading(true)
    openLoginWindow()
  }

  return <Box onClick={onClickLogin}>{children}</Box>
}

const GoogleButton = props => {
  const { children } = props
  const googleClientId = process.env.GOOGLE_LOGIN_CLIENT_ID

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <GoogleLogins {...props}>
        {children || (
          <Button className="mt-4 mr-0 w-full lg:w-392 flex justify-center items-center h-14 border border-solid border-gray capitalize rounded-xl hover:bg-gray-700">
            <GoogleLoginIcon />
            <Typography className="w-180 font-averta text-base ml-5 font-semibold">
              Continue with Google
            </Typography>
          </Button>
        )}
      </GoogleLogins>
    </GoogleOAuthProvider>
  )
}

export default GoogleButton
