import { SvgIcon } from '@mui/material'

export default function logo(props) {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 32 32" {...props}>
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M1.733 2.533C1.733 1.355 2.69.4 3.867.4h16.15c8.144 0 11.66 10.32 5.21 15.291l-4.774 3.682 9.137 8.535a2.133 2.133 0 1 1-2.913 3.118l-9.649-9.013L5.17 31.156a2.133 2.133 0 0 1-3.436-1.69v-14.07c0-5.13 6.122-7.79 9.872-4.287l5.688 5.313 5.33-4.11c3.224-2.486 1.466-7.645-2.606-7.645H3.867a2.133 2.133 0 0 1-2.134-2.134ZM13.87 19.062l-5.177-4.835C7.67 13.27 6 13.997 6 15.396v9.732l7.868-6.066Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#b)"
        fillRule="evenodd"
        d="m20.453 19.374 4.629 4.323-2.913 3.118-5.142-4.803 3.426-2.638Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1="20.15"
          x2="12.453"
          y1="-.12"
          y2="27.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004D34" />
          <stop offset="1" stopColor="#88AE88" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="18.178"
          x2="22.299"
          y1="20.653"
          y2="24.417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004D34" />
          <stop offset="1" stopColor="#508666" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
