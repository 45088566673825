import { SvgIcon } from '@mui/material'

export default function googleLoginIcon(props) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M8.808 1.98a10.372 10.372 0 017.015.063 10.091 10.091 0 013.354 2.115c-.324.36-.677.695-1.013 1.044l-1.915 1.95a5.652 5.652 0 00-2.245-1.347 6.046 6.046 0 00-3.058-.125 6.198 6.198 0 00-3.113 1.762A6.602 6.602 0 006.32 9.945c-1.136-.901-2.273-1.796-3.415-2.696C4.11 4.797 6.27 2.864 8.808 1.98z"
          fill="#EA4335"
        />
        <path
          d="M2.004 9.905c.185-.923.487-1.824.907-2.668 1.137.9 2.274 1.796 3.415 2.697a6.505 6.505 0 000 4.127 1214.66 1214.66 0 01-3.41 2.696c-1.052-2.092-1.371-4.55-.912-6.852z"
          fill="#FBBC05"
        />
        <path
          d="M12.212 10.013h9.798c.325 1.864.252 3.808-.263 5.633-.476 1.67-1.378 3.221-2.637 4.4L15.8 17.43a5.007 5.007 0 002.083-3.279h-5.677c.006-1.378.006-2.758.006-4.138z"
          fill="#4285F4"
        />
        <path
          d="M2.906 16.764c1.136-.896 2.273-1.796 3.41-2.697A6.58 6.58 0 008.7 17.323a6.17 6.17 0 002.318 1.02c.817.172 1.663.15 2.486.007a5.91 5.91 0 002.295-.924l3.31 2.617c-1.193 1.123-2.688 1.887-4.267 2.257-1.747.405-3.594.417-5.33-.057A10.234 10.234 0 015.726 20.3a10.684 10.684 0 01-2.821-3.536z"
          fill="#34A853"
        />
      </g>
    </SvgIcon>
  )
}
