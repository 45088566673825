import { Box } from '@mui/material'
import Logo from '../icons/logo'

const ActivityInfo = ({ className }) => (
  <Box
    className={`${className} pb-6 pt-7 px-10 md:p-10 text-white`}
    sx={{
      backgroundImage: `url(${process.env.CDN_PREFIX}/web/e8264987e83e627a.jpg)`,
      backgroundOrigin: 'border-box',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}
  >
    <Box className="hidden md:flex text-[#88AE88] ml-2 font-averta text-[20px] font-semibold items-center">
      <Logo className="mr-[6px] text-[20px]" /> Renty.AI
    </Box>
    <Box className="hidden md:block font-semibold mt-15 mb-10 text-[26px]">Welcome to Renty.AI</Box>
    <Box className="font-minion text-base md:text-2xl font-bold mb-2 text-center md:text-left">
      💥 Massive rent special
    </Box>
    <Box className="ml-0 md:ml-[30px] text-center md:text-left">
      Some properties rented through Renty.AI are
      <Box component="span" className="text-[#60E2B8] font-semibold italic">
        &nbsp; $30 off &nbsp;
      </Box>
      per month, saving you
      <Box component="span" className="text-[#60E2B8] font-semibold italic">
        &nbsp;$360&nbsp;
      </Box>
      per year
    </Box>
  </Box>
)

export default ActivityInfo
