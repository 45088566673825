import { Box, Typography, TextField, Snackbar, Alert } from '@mui/material'
import Link from 'next/link'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useLazyQuery } from '@apollo/client'
import { statsigLog } from '@/lib/statsigAndGa'
import GoogleButton from './googleButton'
import Close from '../icons/close'
import AppleButton from './appleButton'
import VerCodeInput from './verCodeInput'
import { validateEmail } from '../../lib/commonFunction'
import { GENERATE_CODE, CODE_VERIF_ICATION } from '../../graphqls/queries'
import ActivityInfo from './activityInfo'

const LoginContent = ({ handleClose, value, onSendEmail, isMobile = false }) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [timer, setTimer] = useState(0)

  const [messageOpen, setMessageOpen] = useState({
    open: false,
    message: 'success',
    type: 'success'
  })

  const onCloseMessage = () => setMessageOpen({ ...messageOpen, open: false })

  const [getGenerateCode, { loading }] = useLazyQuery(GENERATE_CODE, {
    fetchPolicy: 'network-only',
    variables: {
      email
    },
    onCompleted: res => {
      const { generateCode = false } = res || {}
      if (generateCode) {
        setTimer(60)
        onSendEmail()
      }

      setMessageOpen({
        open: true,
        message: generateCode ? 'Success' : 'Fail in send',
        type: generateCode ? 'success' : 'error'
      })
    },
    onError: () => {
      setMessageOpen({ open: true, message: 'Fail in send', type: 'error' })
    }
  })

  const [codeVerifIcation] = useLazyQuery(CODE_VERIF_ICATION)

  useEffect(() => {
    let timeOut = null
    if (timer > 0) {
      timeOut = setTimeout(() => setTimer(timer - 1), 1000)
    }

    return () => {
      clearInterval(timeOut)
    }
  }, [timer])

  const getCode = code => {
    if (code.length < 6) {
      return
    }

    codeVerifIcation({
      fetchPolicy: 'network-only',
      variables: {
        email,
        code
      },
      onCompleted: res => {
        const { codeVerification: codeData } = res || {}
        const { status, userInfo } = codeData || {}
        const {
          token = ''
          // email: userEmail,
          // firstName: userFirstName,
          // userId,
          // lastName: lastUserName
        } = userInfo || {}
        if (status) {
          statsigLog('login_in_ways_click', { login_way: 'email' })
          document.cookie = `loginUserToken=${token};path=/`
          window.location.reload()
        } else {
          setMessageOpen({
            open: true,
            message: 'Verification code error',
            type: 'error'
          })
        }
      },
      onError: () => {
        setMessageOpen({
          open: true,
          message: 'Error',
          type: 'error'
        })
      }
    })
  }

  const onChange = e => {
    setEmail(e.target.value)
    setEmailError(!validateEmail(e.target.value))
  }

  const onSubmit = () => {
    if (!validateEmail(email)) {
      setEmailError(!validateEmail(email))
      return
    }
    statsigLog('login_in_ways_click', { login_way: 'email' })
    getGenerateCode()
  }

  return (
    <Box className="flex flex-col md:flex-row w-full">
      <ActivityInfo className="w-full md:w-1/2" />
      <Box className="w-full md:w-1/2 px-5 lg:px-10 relative text-center pb-6 pt-0 md:py-11">
        {!isMobile && (
          <Box
            onClick={handleClose}
            className="absolute right-5 top-5 font-averta flex justify-center items-center text-xl cursor-pointer hover:bg-gray-300 w-3 h-3 rounded-full p-0 ml-auto"
          >
            <Close />
          </Box>
        )}
        <TabContext value={value}>
          <TabPanel className="w-full flex flex-col justify-center items-center p-0" value="1">
            <Box className="w-full mt-2 md:-mt-4">
              <GoogleButton loginSuccess={handleClose} />
              <AppleButton loginSuccess={handleClose} />
            </Box>
            <Box className="flex items-center justify-between my-5 w-full">
              <Box className="h-[1px] w-[135px] lg:w-[164px] border-t border-solid border-[#E9E9E9]" />
              <Typography className="mx-6 text-secondary font-averta text-base">or</Typography>
              <Box className="h-[1px] w-[135px]  lg:w-[164px] border-t border-solid border-[#E9E9E9]" />
            </Box>
            <TextField
              className="w-full h-14 rounded-2xl"
              placeholder="Email"
              onBlur={onChange}
              error={emailError}
              helperText={emailError ? 'Please enter a valid email address' : null}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  fontFamily: 'var(--font-averta)'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #DDD'
                },
                '& .MuiOutlinedInput-input': {
                  color: '#050A22'
                },
                '& .MuiOutlinedInput-input::-webkit-input-placeholder': {
                  color: '#75798D'
                }
              }}
            />
            <LoadingButton
              onClick={onSubmit}
              className={`${
                loading ? 'bg-white-800 text-white-700' : 'bg-brand text-white'
              } w-full h-14 rounded-2xl font-averta text-base capitalize mt-4 font-semibold disabled:bg-gray-250 disabled:text-tertiary disabled:border-gray-300 disabled:border disabled:border-solid`}
              loading={loading}
            >
              Continue with Email
            </LoadingButton>
            <Box className="my-4 md:mt-4 text-center">
              <Typography component="span" className="text-secondary text-xs font-averta">
                By proceeding you agree to Renty.AI’s{' '}
              </Typography>
              <Link
                target="_blank"
                className="underline text-xs font-averta text-black"
                href="/policy"
              >
                Privacy Policy.
              </Link>
            </Box>
          </TabPanel>
          <TabPanel className="flex flex-col items-center p-0" value="2">
            {!isMobile && (
              <Typography className="text-black-headerBlack ml-2 font-averta text-[30px] font-semibold">
                Renty
                <Typography
                  component="span"
                  className="text-brand font-averta text-[30px] font-semibold"
                >
                  .AI
                </Typography>
              </Typography>
            )}
            <Typography className="mt-10 font-averta text-lg font-semibold text-black">
              Check your Email
            </Typography>
            <Typography className="font-averta text-base text-center mt-5 mb-10 text-black">
              We’ve sent you a code to login at
              <br />
              {email}
            </Typography>
            <VerCodeInput getCode={getCode} />
            {/* <Typography className=" font-averta font-bold text-sm mt-4">Resend code</Typography> */}
            <Box>
              <LoadingButton
                onClick={getGenerateCode}
                loading={loading}
                disabled={!!timer}
                className={`${
                  timer ? 'bg-white-800 text-white-700' : 'bg-brand text-white'
                } w-[335px] lg:w-[392px] h-14 rounded-2xl font-averta text-base capitalize mt-6 font-semibold disabled:text-tertiary`}
              >
                Resend code {timer || ''}
              </LoadingButton>
            </Box>
          </TabPanel>
        </TabContext>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={messageOpen.open}
          autoHideDuration={3000}
          onClose={onCloseMessage}
        >
          <Alert elevation={6} onClose={onCloseMessage} severity={messageOpen.type}>
            {messageOpen.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  )
}

export default LoginContent
