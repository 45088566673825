import camelCase from 'lodash/camelCase'
import isFinite from 'lodash/isFinite'

// Replace the dash in class name with hump
const styleTurnHump = styles => {
  const newStyles = {}
  Object.keys(styles).forEach(key => {
    newStyles[camelCase(key)] = styles[key]
  })

  return newStyles
}

const toLowCaseValue = value => value?.toLowerCase().replace(/\s+/g, '')

const getCitySeo = (seoList, route) => {
  const value = toLowCaseValue(route)
  let content = ''

  seoList.find(({ name: cityRoute, area, content: cityContent }) => {
    if (value === toLowCaseValue(cityRoute)) {
      content = cityContent
      return true
    }
    area.forEach(({ name: areaRoute, content: areaContent }) => {
      if (value === toLowCaseValue(areaRoute)) {
        content = areaContent
        return true
      }
      return false
    })
    return false
  })

  return content
}

const handleBedRoom = value => {
  let beds = !!value && Array.isArray(value) ? value : [value]
  beds = beds.reduce((acc, val) => {
    if (isFinite(parseInt(val, 10))) {
      acc.push(parseInt(val, 10))
    }
    return acc
  }, [])
  return beds
}

const validateEmail = email => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(String(email).toLowerCase())
}

export { styleTurnHump, getCitySeo, handleBedRoom, validateEmail }
