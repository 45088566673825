import { Box, Typography } from '@mui/material'
import { ButtonApple } from '@nixjs23n6/next-apple-login'
import { useLazyQuery } from '@apollo/client'
import { statsigLog } from '@/lib/statsigAndGa'
import AppleLoginIcon from '../icons/appleLoginIcon'
import { THIRD_LOGIN } from '../../graphqls/queries'

const AppleButton = props => {
  const { loginSuccess = () => {}, children } = props
  const clientId = process.env.APPLE_LOGIN_CLIENT_ID
  const [login] = useLazyQuery(THIRD_LOGIN)

  const responseApple = response => {
    const { authorization, user, error = null } = response || {}
    if (!error) {
      const { id_token: appleToken = '' } = authorization || {}
      const { name, email = '' } = user || {}
      const { firstName = '', lastName = '' } = name || {}
      login({
        fetchPolicy: 'network-only',
        variables: {
          loginInput: {
            appleLogin: {
              appleFirstName: firstName,
              appleLastName: lastName,
              appleToken,
              email
            }
          }
        },
        onCompleted: res => {
          const { thirdLogin } = res || {}
          const { userInfo = '' } = thirdLogin || {}
          const {
            token = ''
            // email: userEmail,
            // firstName: userFirstName,
            // userId,
            // lastName: lastUserName
          } = userInfo || {}
          document.cookie = `loginUserToken=${token};path=/`
          statsigLog('login_in_ways_click', { login_way: 'apple' })
          loginSuccess(res)
          window.location.reload()
        }
      })
    }
  }

  const onClickLogin = () => {
    statsigLog('login_in_ways_click', { login_way: 'apple' })
  }

  const redirectURI =
    window.location.hostname === 'localhost'
      ? 'https://staging.renty.ai/auth/login'
      : `https://${window.location.host}/auth/login`

  return (
    <Box onClick={onClickLogin}>
      <ButtonApple
        clientId={clientId}
        redirectURI={redirectURI}
        callback={responseApple}
        responseType="id_token"
        responseMode="form_post"
        className="w-full"
      >
        {children || (
          <Box className="w-full lg:w-392 mt-4 flex justify-center items-center h-14 border border-solid border-gray capitalize rounded-xl hover:bg-gray-700 cursor-pointer">
            <AppleLoginIcon />
            <Typography className="w-180 font-averta text-base ml-5 font-semibold text-black">
              Continue with Apple
            </Typography>
          </Box>
        )}
      </ButtonApple>
    </Box>
  )
}

export default AppleButton
