import React, { memo, useState, useEffect, useImperativeHandle } from 'react'

/**
 * 格子验证码输入框
 * @param props
 * @constructor
 */
const VerCodeInput = props => {
  const { style, num = 6, getCode, cRef, loading } = props

  /** 验证码 */
  const [code, setCode] = useState('')

  /** 输入框是否获得焦点 */
  const [isFocus, setIsFocus] = useState(false)

  /** 组件是否销毁 */
  let isUnmounted = false

  /**
   * 暴露给父组件的方法
   */
  useImperativeHandle(cRef, () => ({
    /**
     * 清除当前输入框的内容
     */
    clear: () => {
      if (!isUnmounted) {
        setCode('')
      }
      // eslint rule ask to write this code
      // !isUnmounted && setCode('')
    }
  }))

  useEffect(
    () => () => {
      isUnmounted = true
    },
    []
  )

  const item = itemProps => {
    const { index } = itemProps
    return (
      <div
        className={`md:w-14 w-11 md:h-13 h-10 font-semibold font-averta text-[32px] md:leading-13 leading-10 input_item ${
          code.length > index ? 'valuable' : ''
        } ${loading ? 'bg-tertiary' : ''}`}
        key={index}
      >
        {code.length === index && isFocus ? <span /> : code[index]}
      </div>
    )
  }

  /**
   * 输入框格子
   * @param props
   */
  const Item = memo(item)

  return (
    <div style={style} className="ver_code_input_main">
      {Array(num)
        .fill('')
        .map((_, index) => (
          <Item key={[index].join('-')} index={index} />
        ))}
      <input
        type="number"
        className="caret-transparent"
        maxLength={num}
        value={code}
        onFocus={() => !isUnmounted && setIsFocus(true)}
        onBlur={() => !isUnmounted && setIsFocus(false)}
        onChange={e => {
          const { value } = e.target
          if (!isUnmounted && value.length <= num) {
            setCode(value)
            if (toString.call(getCode) === '[object Function]') getCode(value) // 传递给父组件
          }
        }}
      />
    </div>
  )
}

export default VerCodeInput
