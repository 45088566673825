// import { SvgIcon } from '@mui/material'

// export default function close(props) {
//   return (
//     <SvgIcon {...props}>
//       <path
//         d="M20.6007 3.41297C20.0683 2.88055 19.2082 2.88055 18.6758 3.41297L12 10.0751L5.32423 3.39932C4.79181 2.86689 3.93174 2.86689 3.39932 3.39932C2.86689 3.93174 2.86689 4.79181 3.39932 5.32423L10.0751 12L3.39932 18.6758C2.86689 19.2082 2.86689 20.0683 3.39932 20.6007C3.93174 21.1331 4.79181 21.1331 5.32423 20.6007L12 13.9249L18.6758 20.6007C19.2082 21.1331 20.0683 21.1331 20.6007 20.6007C21.1331 20.0683 21.1331 19.2082 20.6007 18.6758L13.9249 12L20.6007 5.32423C21.1195 4.80546 21.1195 3.93174 20.6007 3.41297Z"
//         fill={props.color || 'black'}
//       />
//       <path
//         d="M11.7338 0.275313C11.3788 -0.0796358 10.8055 -0.0796358 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.621161 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313V0.275313Z"
//         fill={props.color || 'black'}
//       />
//     </SvgIcon>
//   )
// }
export default function close(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7338 0.275313C11.3788 -0.0796358 10.8055 -0.0796358 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.621161 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313V0.275313Z"
        fill={props.color || '#050A22'}
      />
    </svg>
  )
}
