import { SvgIcon } from '@mui/material'

export default function appleLoginIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M18.0331 12.6879C18.0641 16.0172 20.9678 17.1251 21 17.1393C20.9754 17.2174 20.536 18.7181 19.4702 20.2683C18.5488 21.6085 17.5925 22.9438 16.0861 22.9714C14.6059 22.9985 14.13 22.0979 12.4377 22.0979C10.7459 22.0979 10.2171 22.9437 8.81591 22.9985C7.36184 23.0533 6.25458 21.5493 5.32554 20.214C3.42717 17.4827 1.97642 12.4959 3.92441 9.12967C4.89213 7.45801 6.62152 6.39945 8.49862 6.3723C9.92648 6.3452 11.2742 7.3283 12.1471 7.3283C13.0194 7.3283 14.6572 6.14603 16.3789 6.31966C17.0997 6.34952 19.1229 6.60942 20.4221 8.50192C20.3174 8.56651 18.0079 9.9045 18.0331 12.6879V12.6879ZM15.2512 4.51263C16.0232 3.58268 16.5427 2.2881 16.401 1C15.2883 1.04451 13.9427 1.73794 13.1446 2.66738C12.4293 3.49044 11.8028 4.8078 11.9719 6.07041C13.2121 6.16591 14.4792 5.44317 15.2512 4.51263"
        fill={props.color || '#050A22'}
      />
    </SvgIcon>
  )
}
